import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listCameras, listCompanys, getProject } from "../../graphql/queries";
import {
    createProject,
    updateCamera,
    updateProject,
    createEvent,
} from "../../graphql/mutations";
import { TextInput } from "../../components/TextInput";
import DropdownInput from "../../components/Dropdown";
import "../../styles/pages/projects/add-edit-project.scss";
import PropTypes from "prop-types";
import { Row, Col, Modal } from "react-bootstrap";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import Select from "react-select";
import translate from "../../i18n/translate";
import { FaPlus } from "react-icons/fa";
import { useIntl } from "react-intl";
import _ from "lodash";
import moment from "moment";
import { Auth } from "aws-amplify";
import { BiX } from "react-icons/bi";


    

const AddEditProject = ({ onHide, modal }) => {
    const { editProject, id, show } = modal;
    const [ projectData, setProjectData ] = useState({
        name: "",
        location: "",
        company: "",
        pendingDeletion: false,
        latitude: "",
        longitude: "",
        cameras: [ {} ],
    });
    const [ camerasState, setCamerasState ] = useState([ { id: "", name: "", cameraAlias: "" } ]);
    const [ loader, setLoader ] = useState(false);
    const [ error, setError ] = useState("");
    const intl = useIntl();
    const [ ListCompanys, setListCompanys ] = useState([]);
    const [ cameraOptions, setCameraOptions ] = useState([]);
    const [ toDeleteCameras, setToDeleteCameras ] = useState([]);
    const [ cameraIDs, setCameraIDs ] = useState({});
    const fetchProjectFunc = useRef();

    const events = {
        log: "",
        user: Auth.user.username,
        expire: "",
        logType: "project"
    };


    let now = (Date.now() + 15552000000) / 1000;
    events.expire =  Math.round(now);

    const inputFields1 = [
        {
            label: translate("project-name"),
            name: "name",
            type: "text",
        },
        {
            label: translate("location"),
            name: "location",
            type: "text",
        },
        {
            label: translate("assign-to-company"),
            name: "company",
            type: "dropDown",
            items: ListCompanys.map((val) => val.name),
        },
    ];

    const inputFields2 = [
        {
            label: translate("latitude"),
            name: "latitude",
            type: "number",
        },
        {
            label: translate("longitude"),
            name: "longitude",
            type: "number",
        },
    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected && "#1181F2",
            backgroundColor: "none",
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
        menu: (provided) => ({
            ...provided,
            padding: 5,
            font: 20,
        }),
    };

    const onChangeCamera = (camera, index) => {
        let camerasArr = camerasState.slice();
        let CameraOptions = [ ...cameraOptions ];
        if (camerasArr[ index ].id) {
            let changedCamera = { id: camerasArr[ index ].id, delete: true };
            if (camerasArr[ index ]?.cameraName) {
                cameraOptions.push({
                    value: camerasArr[ index ]?.id,
                    label: camerasArr[ index ]?.cameraName,
                });
            } else {
                cameraOptions.push({
                    value: camerasArr[ index ].id,
                    label: camerasArr[ index ].name,
                });
            }
            let deleteCam = [ ...toDeleteCameras ];
            deleteCam.push(changedCamera);
            setToDeleteCameras(deleteCam);
            camerasArr[ index ].id = camera.value;
            camerasArr[ index ].name = camera.label;
            camerasArr[ index ].start_date = moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]");
            camerasArr[ index ].delete = false;
            camerasArr[ index ].edit = true;
        } else {
            camerasArr[ index ].id = camera.value;
            camerasArr[ index ].name = camera.label;
            camerasArr[ index ].start_date = moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]");
            camerasArr[ index ].delete = false;
            camerasArr[ index ].edit = true;
        }
        setProjectData({ ...projectData, cameras: camerasArr });
        _.remove(CameraOptions, function (val) {
            return val === camera;
        });
        setCameraOptions(CameraOptions);
    };

    const onDeleteCamera = (index) => {
        const newCameraState = [ ...camerasState ];
        newCameraState.splice(index, 1);
        setCamerasState(newCameraState);
        var CamOptions = [ ...cameraOptions ];
        if (projectData.cameras[ index ]?.id) {
            if (projectData.cameras[ index ]?.cameraName) {
                CamOptions.push({
                    value: projectData.cameras[ index ]?.id,
                    label: projectData.cameras[ index ]?.cameraName,
                });
            } else {
                CamOptions.push({
                    value: projectData.cameras[ index ].id,
                    label: projectData.cameras[ index ].name,
                });
            }
            setCameraOptions(CamOptions);
            let deleteCam = [ ...toDeleteCameras ];
            deleteCam.push({
                id: projectData.cameras[ index ].id,
                delete: true,
                edit: false,
                end_date: moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]"),
                start_date: cameraIDs[ projectData.cameras[ index ].id ] ? cameraIDs[ projectData.cameras[ index ].id ][ 0 ] : "start_date"
            });
            setToDeleteCameras(deleteCam);
            var ProjectsData = { ...projectData };
            ProjectsData.cameras.splice(index, 1);
            setProjectData(ProjectsData);
        }

    };

    const onCloseModal = (reload = true) => {
        setLoader(false);
        onHide();
        setError("");
        setProjectData({
            name: "",
            location: "",
            company: "",
            latitude: "",
            pendingDeletion: false,
            longitude: "",
            cameras: [],
        });
        if (reload) window.location.reload();
    };

    const fetchListCameraAndCompany = () => {
        API.graphql(graphqlOperation(listCompanys, { limit: 1000 }))
            .then(
                ({
                    data: {
                        listCompanys: { items },
                    },
                }) => {
                    items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    setListCompanys(items);
                }
            )
            .catch(({ errors }) => {
                setError(errors[ 0 ].message);
            });
        API.graphql(graphqlOperation(listCameras, { limit: 1000 }))
            .then(
                ({
                    data: {
                        listCameras: { items },
                    },
                }) => {
                    var camera = [];
                    items.forEach((val) => {
                        if (!val.project) {
                            camera.push({ value: val.id, label: val.cameraName });
                        }
                    });
                    const sortedCamera = camera.sort(
                        (a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)
                    );
                    setCameraOptions(sortedCamera);
                }
            )
            .catch(({ errors }) => {
                setError(errors[ 0 ].message);
            });
    };

    const fetchProject = () => {
        API.graphql(graphqlOperation(getProject, { id: id }))
            .then(({ data: { getProject } }) => {
                if (getProject.cameraIDs) {
                    setCameraIDs(JSON.parse(getProject.cameraIDs));
                }
                setProjectData({
                    ...projectData,
                    name: getProject?.name,
                    location: getProject?.location,
                    company: getProject?.company?.name,
                    latitude: getProject?.latitude,
                    pendingDeletion: getProject?.pendingDeletion,
                    longitude: getProject?.longitude,
                    cameras: getProject?.cameras?.items,
                });
                let camArr = [];
                getProject.cameras.items.forEach((val) => {
                    camArr.push({
                        name: val.cameraName,
                        id: val.id,
                        cameraAlias: val.cameraAlias,
                    });
                });
                setCamerasState(camArr);
                setError("");
            })
            .catch(({ errors }) => {
                setError(errors[ 0 ].message);
            });
    };
    fetchProjectFunc.current = fetchProject;


    useEffect(() => {
        fetchListCameraAndCompany();

        if (editProject) {
            fetchProjectFunc.current();
        }

    }, [ editProject, id ]);

    const onSubmit = async (e) => {
        e.preventDefault();
        const {
            name,
            location,
            company,
            latitude,
            longitude,
            pendingDeletion,
            cameras,
        } = projectData;
        var companyID;
        setLoader(true);
        ListCompanys.forEach((comp) => {
            if (comp.name === company) {
                companyID = comp.id;
            }
        });
        if (!editProject) {
            if (companyID) {
                let newCameraIDs = { ...cameraIDs };
                cameras.map((camera) => {
                    return newCameraIDs[ camera.id ] = [ moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]"), "end_date" ];
                });
                setCameraIDs(newCameraIDs);
                await API.graphql(
                    graphqlOperation(createProject, {
                        input: { name, location, latitude, longitude, pendingDeletion, companyID, cameraIDs: JSON.stringify(newCameraIDs) },
                    })
                )
                    .then( async ({ data: { createProject } }) => {
                        try {
                            events.log = "A project " + name + " has been created";
                            const event = { ...events };
                            await API.graphql(graphqlOperation(createEvent, { input: event }));
                        } catch (err) {
                            throw err;
                        }
                        setLoader(false);
                        setError("");
                        cameras.length ? 
                            cameras.forEach((val) => {
                                return API.graphql(
                                    graphqlOperation(updateCamera, {
                                        input: {
                                            id: val.id,
                                            cameraProjectId: createProject.id,
                                            cameraAlias: val.cameraAlias,
                                        },
                                    })
                                )
                                    .then(() => {
                                        setError("");
                                        onCloseModal();
                                    })
                                    .catch(({ errors }) => {
                                        setError(errors && errors[ 0 ].message);
                                        setLoader(false);
                                    });
                            }) : onCloseModal();
                        window.location.href = `/projects/${createProject.id}`;
                    })
                    .catch(({ errors }) => {
                        setError(errors[ 0 ].message);
                        setLoader(false);
                    });
            } else {
                setLoader(false);
                setError("Please assign project to any company");
            }

        } else {
            let newCameraIDs = { ...cameraIDs };
            cameras.forEach((camera) => {
                if (camera.edit) {
                    if (camera.start_date) {
                        return newCameraIDs[ camera.id ] = [ camera.start_date, "end_date" ];
                    }
                }
            });
            if (toDeleteCameras.length) {
                toDeleteCameras.forEach((camera) => {
                    if (camera.delete) {
                        if (camera.end_date) {
                            return newCameraIDs[ camera.id ] = [ cameraIDs[ camera.id ] ? cameraIDs[ camera.id ][ 0 ] : "start_date", camera.end_date ];
                        }
                    }
                });
            }
            setCameraIDs(newCameraIDs);
            API.graphql(
                graphqlOperation(updateProject, {
                    input: {
                        id: id,
                        name,
                        location,
                        latitude,
                        longitude,
                        pendingDeletion,
                        companyID,
                        cameraIDs: JSON.stringify(newCameraIDs)
                    },
                })
            ).then(async() => {
                setLoader(false);
                setError("");
                if (toDeleteCameras.length) {
                    await toDeleteCameras.forEach(async(val) => {
                        if (val.delete) {
                            await API.graphql(
                                graphqlOperation(updateCamera, {
                                    input: {
                                        id: val.id,
                                        cameraProjectId: null,
                                        cameraAlias: null,
                                        status: "inactive",
                                        latestTimelapseLink: null
                                    },
                                })
                            ).catch(({ errors }) => {
                                setError(errors[ 0 ].message);
                                setLoader(false);
                            });
                        }
                    });
                }
                if (cameras.length){
                    await cameras.forEach(async(val) => {
                        if (val.edit) {
                            await API.graphql(
                                graphqlOperation(updateCamera, {
                                    input: {
                                        id: val.id,
                                        cameraProjectId: id,
                                        cameraAlias: val.cameraAlias,
                                    },
                                }))
                                .catch(({ errors }) => {
                                    setError(errors[ 0 ].message);
                                    setLoader(false);
                                });
                        }
                    });
                }
                    
            }).then(() => {
                onCloseModal(true);
            })
                .catch(({ errors }) => {
                    setError(errors[ 0 ].message);
                    setLoader(false);
                });
        };
    };

    return (
        <Modal className="add-project" show={show} onHide={() => onCloseModal()}>
            <span onClick={onCloseModal} className="add-project__close-modal btn">
                <BiX
                    key={2}
                    color="#1181F2"
                    size={30}
                />
            </span>
            <h2>{editProject ? translate("edit-project") : translate("create-new-project")}</h2>
            <span className="text-center text-danger">{error}</span>
            <form action="" onSubmit={onSubmit}>
                <Row>
                    {inputFields1.map((field, i) => (
                        <Col md={6} sm={12} key={i + 1}>
                            {field.type === "dropDown" ? (
                                <DropdownInput
                                    title={
                                        projectData[ field.name ]
                                            ? projectData[ field.name ]
                                            : translate("select-company")
                                    }
                                    name={field.name}
                                    item={field.items}
                                    label={field.label}
                                    iconSize={20}
                                    onSelect={(val) => {
                                        setProjectData({ ...projectData, [ field.name ]: val });
                                    }
                                    }
                                />
                            ) : (
                                <TextInput
                                    key={i + 1}
                                    type={field.type}
                                    value={projectData[ field.name ]}
                                    onChange={(event) =>
                                        setProjectData({
                                            ...projectData,
                                            [ field.name ]: event.target.value,
                                        })
                                    }
                                    label={field.label}
                                    name={field.name}
                                    required={true}
                                />
                            )}
                        </Col>
                    ))}
                </Row>
                <div className="add-project__input-groupname">{translate("gps-location")}</div>
                <Row>
                    {inputFields2.map((field, i) => (
                        <Col md={6} sm={12} key={i + 1}>
                            <TextInput
                                key={i + 1}
                                type={field.type}
                                value={projectData[ field.name ]}
                                label={field.label}
                                name={field.name}
                                required={false}
                                step="any"
                                onChange={(event) =>
                                    setProjectData({
                                        ...projectData,
                                        [ field.name ]: event.target.value,
                                    })
                                }
                            />
                        </Col>
                    ))}
                </Row>
                <div className="add-project__cameras">
                    <div className="add-project__camera-groupname">{translate("choose-cameras")}</div>
                    <FaPlus
                        size={16}
                        color="rgba(0, 0, 0, 0.62)"
                        className="add-project__camera-plus"
                        onClick={() => setCamerasState(camerasState.concat({ id: "", name: "", cameraAlias: "" }))}
                    />
                </div>
                <Row>
                    {camerasState.map((camera, i) => (
                        <Col className="add-camera-column" lg={4} md={6} sm={12} key={i}>
                            <div
                                className="add-camera__edit"
                                onClick={() => onDeleteCamera(i)}
                            >
                                <BiX color="#1181F2" size={22} />
                            </div>
                            {camerasState[ i ].id && (
                                <input
                                    type="text"
                                    placeholder={intl.formatMessage({ id: "add-name" })}
                                    className="add-camera__add-name"
                                    value={camera.cameraAlias}
                                    onChange={(event) => {
                                        let camState = camerasState.slice();
                                        camState[ i ].cameraAlias = event.target.value;
                                        camState[ i ].delete = false;
                                        camState[ i ].edit = true;
                                        setProjectData({ ...projectData, cameras: camState });
                                    }}
                                />
                            )}
                            <Select
                                options={cameraOptions}
                                isSearchable={true}
                                styles={customStyles}
                                placeholder={translate("select-cam")}
                                inputValue={camera.name}
                                value={camera.name}
                                onChange={(val) => onChangeCamera(val, i)}
                            />
                        </Col>
                    ))}
                </Row>
                <div className="add-user__btn-div">
                    <ButtonPrimary
                        title={editProject ? translate("save") : translate("create")}
                        type="submit"
                        backgroundColor="#fff"
                        color="#000"
                        disabled={loader ? true : false}
                        loader={loader}
                        loaderColour="#1181f2"
                    />
                </div>
            </form>
        </Modal>
    );
};

AddEditProject.propTypes = {
    onHide: PropTypes.func,
    modal: PropTypes.object,
};

export default AddEditProject;
