import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import DropdownInput from "../../components/Dropdown";
import "../../styles/pages/users/users.scss";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listProjects } from "../../graphql/queries";
import "../../styles/pages/historicalArchive/historicalArchive.scss";
import UserTable from "../Users/UserTable.jsx";
import {
  getProject,
  getUsersForSuperUser,
  listUsersForUserTable,
  listUsersForUserProjects,
  listCompanysForUsersTable,
} from "../../helpers/manualQueries";
import {
  onCreateUser,
  onUpdateUser,
  onDeleteUser,
  onCreateUserProject,
  onDeleteUserProject,
} from "../../graphql/subscriptions";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { IoMdRefresh } from "react-icons/io";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import AddEditUser from "./AddEditUser";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteUser from "../../components/DeleteModal";
import Pagination from "../../components/Pagination";
import Select from "react-select";
import { createEvent, updateUser } from "../../graphql/mutations";
import translate from "../../i18n/translate";

const Users = ({ role, isNavbarHidden }) => {
  const [users, setUsers] = useState([]);
  const [usersForFilter, setUsersForFilter] = useState([]);
  const [projects, setProjects] = useState([]);

  const [deleteUserState, openDeleteUser] = useState({
    name: "",
    show: false,
    id: "",
  });
  const isSM = useMediaQuery("(max-width:640px)");
  const [modal, openModal] = useState({
    editUser: false,
    show: false,
    id: "",
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [currentItem, setCurrentItem] = useState([]);
  const [itemPerPage] = useState(36);
  const [filter, setFilter] = useState(translate("surname"));
  const [filterValue, setFilterValue] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [loader, setLoader] = useState(false);
  const [sortAsc, setSortAsc] = useState(true);
  const [sortBy, setSortBy] = useState("lastname");
  const [boxIndex, setBoxIndex] = useState(null);
  const [boxDisplay, setBoxDisplay] = useState("none");
  const projectBox = useRef(null);

  const events = {
    log: "",
    user: Auth.user.username,
    expire: "",
    logType: "user",
  };
  const [listCompanysData, setListCompanysData] = useState([]);
  const tempUseEffectFunc = useRef();
  const onCreate = useRef();
  const onUpdate = useRef();
  const onDelete = useRef();
  const onCreateUserProjectFunc = useRef();
  const onDeleteUserProjectFunc = useRef();
  const [valx, setValx] = useState(null);

  let now = (Date.now() + 15552000000) / 1000;
  events.expire = Math.round(now);

  useEffect(() => {
    tempUseEffectFunc.current();
  }, [filter, filterValue]);

  useEffect(() => {
    document.body.addEventListener("click", handleClickOutside);
  }, []);

  const filterOptions = {
    status: [
      { value: "Admin", label: translate("admin") },
      { value: "Crew", label: translate("crew") },
      { value: "SuperUser", label: translate("super-user") },
      { value: "ClientAll", label: translate("client-all") },
      { value: "ClientCamOnly", label: translate("client-cam-only") },
    ],
    project: projects.map((item) => {
      const project = role === "superuser" ? item.projects : item;
      return { value: project.id, label: project.name };
    }),
  };

  function handleClickOutside(event) {
    if (projectBox.current && !projectBox.current.contains(event.target)) {
      setBoxIndex(null);
      setBoxDisplay("none");
    }
  }

  const fetchUsers = async () => {
    setLoader(true);
    try {
      if (filter?.props.id === "status" && filterValue !== null) {
        const requestData = await API.graphql(
          graphqlOperation(listUsersForUserTable, {
            filter: {
              role: {
                eq: filterValue,
              },
            },
            limit: 10000,
          })
        );
        const requests = requestData.data.listUsers.items;
        setUsers(requests);
        setLoader(false);
      } else if (filter?.props.id === "surname" && filterValue !== null) {
        const filteredUsers = usersForFilter.filter((user) => {
          if (user.lastname.indexOf(filterValue) !== -1) {
            return user;
          } else return false;
        });
        setUsers(filteredUsers);
        setLoader(false);
      } else if (filter?.props.id === "username" && filterValue !== null) {
        const filteredUsers = usersForFilter.filter((user) => {
          if (user.username.indexOf(filterValue) !== -1) {
            return user;
          } else return false;
        });
        setUsers(filteredUsers);
        setLoader(false);
      } else if (filter?.props.id === "project" && filterValue !== null) {
        if (role === "superuser") {
          const requestData = await API.graphql(
            graphqlOperation(getUsersForSuperUser, {
              limit: 10000,
              id: filterValue,
            })
          );
          const requests = requestData.data.getProject.users.items;
          const data = requests
            .map((item) => item.users)
            .filter((user) => user.role !== "Admin")
            .filter((user) => user.role !== "Crew");

          setUsers(data);
          setUsersForFilter(data);
        } else {
          const requestData = await API.graphql(
            graphqlOperation(getProject, { id: filterValue, limit: 5000 })
          );
          const requests = requestData.data.getProject.users.items.map(
            (item) => item.users
          );
          setUsers(requests);
        }

        setLoader(false);
      } else if (filter?.props.id === "company" && filterValue !== null) {
        let temp = [];
        Array.isArray(listCompanysData) &&
          listCompanysData.forEach((item) => {
            if (filterValue === item?.name) {
              Array.isArray(item?.projects?.items) &&
                item.projects.items.forEach((item1) => {
                  Array.isArray(item1?.users?.items) &&
                    item1.users.items.forEach((item2) => {
                      temp.push(item2?.users);
                    });
                });
            } else {
              const uniqueTemp = temp.filter(
                (v, i, a) => a.findIndex((t) => t.id === v.id) === i
              );
              setUsers(uniqueTemp);
              setLoader(false);
            }
          });
      } else {
        if (role === "superuser") {
          const users = [];
          for (let i = 0; i < projects.length; i++) {
            const requestData = await API.graphql(
              graphqlOperation(getUsersForSuperUser, {
                limit: 10000,
                id: projects[i].projectID,
              })
            );
            const requests = requestData.data.getProject.users.items;
            requests.map((user) => {
              users.push(user);
            });
          }
          const uniqueUsers = users
            .filter(
              (v, i, a) => a.findIndex((v2) => v2.users.id === v.users.id) === i
            )
            .sort((a, b) =>
              a.users.firstname > b.users.firstname
                ? 1
                : b.users.firstname > a.users.firstname
                ? -1
                : 0
            )
            .map((item) => item.users)
            .filter((user) => user.role !== "Admin")
            .filter((user) => user.role !== "Crew");
          setUsers(uniqueUsers);
          setUsersForFilter(uniqueUsers);
        } else {
          const requestData = await API.graphql(
            graphqlOperation(listUsersForUserTable, { limit: 10000 })
          );
          const requests = requestData.data.listUsers.items;
          setUsers(requests);
          setUsersForFilter(requests);
        }
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [projects, openModal.show]);

  const fetchListProjects = async () => {
    try {
      if (role === "superuser") {
        const userProjectsData = await API.graphql(
          graphqlOperation(listUsersForUserProjects, {
            filter: { username: { eq: Auth.user.username } },
            sortDirection: "DESC",
            limit: 10000,
          })
        );
        const data = userProjectsData.data.listUsers.items[0].projects.items;
        setProjects(data);
      } else {
        const requestData = await API.graphql(
          graphqlOperation(listProjects, { limit: 10000 })
        );
        const requests = requestData.data.listProjects.items;
        setProjects(requests);
      }
    } catch (err) {
      throw err;
    }
  };

  const tempUseEffect = () => {
    fetchListProjects();
    fetchListCompany();
  };
  tempUseEffectFunc.current = tempUseEffect;

  useEffect(() => {
    onCreate.current = API.graphql(graphqlOperation(onCreateUser)).subscribe({
      next: (createUserData) => {
        let createduserData = createUserData?.value?.data?.onCreateUser;
        const createdByUser = Auth?.user?.username === createduserData?.createdBy;

        if (createdByUser) {
          setUsers((previousData) => [...previousData, createduserData]);
        }
        
      },
    });

    onUpdate.current = API.graphql(graphqlOperation(onUpdateUser)).subscribe({
      next: (updateUserData) => {
        const updateduser = updateUserData.value.data.onUpdateUser;
        setUsers((previousData) =>
          previousData.map((user) => {
            if (user.id === updateduser.id) {
              return (user = updateduser);
            } else return user;
          })
        );
      },
    });

    onDelete.current = API.graphql(graphqlOperation(onDeleteUser)).subscribe({
      next: (deletedUserData) => {
        let updateduser = deletedUserData?.value?.data?.onDeleteUser;
        setUsers((previousData) =>
          previousData.filter((user) => {
            if (user.id !== updateduser.id) return user;
            else return false;
          })
        );
      },
    });

    onCreateUserProjectFunc.current = API.graphql(
      graphqlOperation(onCreateUserProject)
    ).subscribe({
      next: ({
        value: {
          data: { onCreateUserProject },
        },
      }) => {
        setUsers((allUsers) => {
          let updatedAllUsers = allUsers.filter((user) => {
            if (onCreateUserProject.userID === user.id) {
              user.projects.items.push({
                id: onCreateUserProject.id,
                createdAt: onCreateUserProject.createdAt,
                projectID: onCreateUserProject.projectID,
                updatedAt: onCreateUserProject.updatedAt,
                userID: onCreateUserProject.userID,
              });

              return user;
            } else return user;
          });

          return updatedAllUsers;
        });
      },
    });

    onDeleteUserProjectFunc.current = API.graphql(
      graphqlOperation(onDeleteUserProject)
    ).subscribe({
      next: ({
        value: {
          data: { onDeleteUserProject },
        },
      }) => {
        setUsers((allUsers) => {
          let updatedAllUsers = allUsers.filter((user) => {
            if (user.id === onDeleteUserProject.userID) {
              let updatedUser = user.projects.items.filter((project, i) => {
                if (project.projectID !== onDeleteUserProject.projectID) {
                  return project;
                } else return false;
              });
              return updatedUser;
            } else return user;
          });
          return updatedAllUsers;
        });
      },
    });

    return () => {
      onCreate.current.unsubscribe();
      onUpdate.current.unsubscribe();
      onDelete.current.unsubscribe();
      onCreateUserProjectFunc.current.unsubscribe();
      onDeleteUserProjectFunc.current.unsubscribe();
    };
  }, []);

  const sortingArrorws = () => {
    return <>{sortAsc ? <BsArrowDown /> : <BsArrowUp />}</>;
  };

  const comlumnHeaderWithSorting = (value, column) => {
    return (
      <div
        className="historicalArchive__sorting"
        onClick={() => {
          setSortAsc(!sortAsc);
          setSortBy(value);
        }}
      >
        {sortBy === value && sortingArrorws()}
        {translate(column)}
      </div>
    );
  };

  const handleSorting = () => {
    const sortedItems = users.sort((a, b) => {
      if (sortAsc)
        return a[sortBy] > b[sortBy] ? 1 : b[sortBy] > a[sortBy] ? -1 : 0;
      else return b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0;
    });
    const indexOfLastItem = (currentPage + 1) * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItem(currentItems);
  };

  useEffect(() => {
    handleSorting();
  }, [users, sortAsc, currentPage, sortBy]);

  const paginate = (selectedPage) => {
    let selected = selectedPage.selected;
    setCurrentPage(selected);
    document.documentElement.scrollTop = 0;
  };

  const CompanyFilterOptions = listCompanysData.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const fetchListCompany = () => {
    API.graphql(graphqlOperation(listCompanysForUsersTable)).then(
      ({
        data: {
          listCompanys: { items },
        },
      }) => {
        setListCompanysData(items);
      }
    );
  };

  const handleFilterChange = (filterValue) => {
    setFilterValue(filterValue.value);
    setValx(filterValue);
  };

  const deleteUserHandle = async () => {
    setDeleteLoader(true);
    try {
      API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: deleteUserState.userId,
            pendingDeletion: true,
          },
        })
      ).then(() => {
        try {
          events.log = "A user " + deleteUserState.name + " has been deleted";
          const event = { ...events };
          API.graphql(graphqlOperation(createEvent, { input: event }));
        } catch (err) {
          throw err;
        }
        setDeleteLoader(false);
        onHide();

        setTimeout(() => {
          fetchUsers();
        }, [2000]);
      });
    } catch (err) {
      setDeleteError(err?.errors[0]?.message);
      setDeleteLoader(false);
    }
  };

  const onHide = () => {
    openDeleteUser({
      name: "",
      show: false,
      id: "",
    });
    setDeleteLoader(false);
  };

  const superUserFiter = [
    translate("project"),
    translate("surname"),
    translate("username"),
  ];
  const adminFilter = [
    translate("status"),
    translate("project"),
    translate("surname"),
    translate("username"),
    translate("company"),
  ];

  const openModalFunc = (id) => {
    openModal({
      editUser: true,
      show: true,
      id: id,
    });
  };
  const openDeleteModalFunc = (username, id, projects, role) => {
    openDeleteUser({
      name: username,
      show: true,
      userId: id,
      projects: projects,
      groupname: role,
    });
  };
  const boxDisplayFunc = (e, index) => {
    e.stopPropagation();
    setBoxIndex(index);
    setBoxDisplay("block");
  };
  const boxIndexFunc = () => {
    setBoxDisplay("none");
  };

  return (
    <div className="users">
      {isSM ? (
        <div className="users__cover">
          <ButtonPrimary
            onClick={() =>
              openModal({
                editUser: false,
                show: true,
                editUserData: {},
              })
            }
            backgroundColor="#ffff"
            color="#000000"
            title={translate("add-user")}
          />
        </div>
      ) : (
        <div
          className="users__filter"
          style={{ width: "50%", float: "left", justifyContent: "flex-start" }}
        >
          {isSM ? null : (
            <ButtonPrimary
              onClick={() =>
                openModal({
                  editUser: false,
                  show: true,
                  editUserData: {},
                })
              }
              backgroundColor="#ffff"
              color="#000000"
              title={translate("add-user")}
            />
          )}
        </div>
      )}
      <div
        className="users__filter"
        style={{ width: isSM ? "100%" : "50%", float: "left" }}
      >
        <div className="overview__dropdown">
          <DropdownInput
            name="filter"
            item={role === "superuser" ? superUserFiter : adminFilter}
            label={translate("filter-by")}
            iconSize={16}
            title={filter !== null ? filter : translate("surname")}
            onSelect={(val) => {
              setFilter(val);
              setFilterValue(null);
            }}
          />
        </div>

        {!(
          filter?.props?.id === "surname" || filter?.props?.id === "username"
        ) ? (
          <Select
            options={
              filter?.props?.id === "company"
                ? CompanyFilterOptions
                : filterOptions[filter?.props.id]
            }
            onChange={handleFilterChange}
            value={valx}
          />
        ) : (
          <input
            onChange={(e) => setFilterValue(e.target.value)}
            value={filterValue || ""}
            className="overview__searchInput"
          />
        )}

        <span
          className="user__filter-reload btn"
          onClick={() => {
            setFilterValue(null);
            setValx(null);
          }}
        >
          <IoMdRefresh color="#1181F2" size={28} />
        </span>
      </div>
      <UserTable
        openDeleteModalFunc={openDeleteModalFunc}
        openModalFunc={openModalFunc}
        isNavbarHidden={isNavbarHidden}
        currentItem={currentItem}
        comlumnHeaderWithSorting={comlumnHeaderWithSorting}
        loader={loader}
        boxDisplay={boxDisplay}
        boxDisplayFunc={boxDisplayFunc}
        boxIndex={boxIndex}
        boxIndexFunc={boxIndexFunc}
        loggedInRole={role}
      />
      <AddEditUser
        modal={modal}
        projectList={projects}
        currentItem={currentItem}
        userRole={role}
        onHide={() =>
          openModal({
            editUser: false,
            show: false,
            id: "",
          })
        }
      />
      <DeleteUser
        open={deleteUserState}
        deleteFunc={deleteUserHandle}
        loader={deleteLoader}
        error={deleteError}
        onHide={onHide}
        canDelete={true}
      />
      <div className="pagination">
        <Pagination
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          totalItems={users.length}
          paginate={paginate}
          path="/users"
        />
      </div>
    </div>
  );
};

Users.propTypes = {
  role: PropTypes.string,
  isNavbarHidden: PropTypes.bool,
};

export default Users;
