import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "../../styles/pages/projects/single-project.scss";
import { Col } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Storage } from "aws-amplify";
import { TiMediaRecord } from "react-icons/ti";
import moment from "moment";
import translate from "../../i18n/translate";
import greyCamera from "../../assets/images/greycam.png";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import greyCameraDark from "../../assets/images/greycam-dark.png";

const SingleProjectCard = ({
    camera,
    listView,
    Role,
    setCameraPopUp,
    setCameraId,
    onClickFunc,
    setLivePopUp,
    setLiveState,
    setTimelapsePopup,
    history,
    params,
    setPinnedCamFunc,
    pinnedCams,
}) => {
    const [ showPreviousImage, setShowPreviouseImage ] = useState(false);
    const datetime = camera.imagesByDate?.items?.length
        ? camera.imagesByDate?.items[ showPreviousImage ? 1 : 0 ]?.datetime
        : "";
    const setTime = datetime?.split("T")[ 1 ];
    const projectStartDate = JSON.parse(camera.project.cameraIDs)[ camera.id ][ 0 ];
    const [ theme, setTheme ] = useState("light");
    
    useEffect(() => {
        setTheme(window.localStorage.getItem("theme"));
    }, [ window.localStorage.getItem("theme") ]);

    const CamsImageProject = (imageClassName, defaultClassName) => {
        const cameraDataUseEffFunc = useRef({});
        const image = camera?.imagesByDate?.items[ 0 ];
        const s3ObjectPathThumbnail =
            camera?.imagesByDate?.items[ 0 ]?.s3ObjectPathThumbnail;
        const imageDateTime = camera?.imagesByDate?.items[ 0 ]?.datetime;
        const [ imageURI, setImageURI ] = useState(null);

        const cameraDataUseEff = () => {
            if ( projectStartDate < imageDateTime) {
                if (image?.s3ObjectPathThumbnail === null) {
                    const previousImage = camera?.imagesByDate?.items[ 1 ];
                    Storage.get(previousImage?.s3ObjectPathThumbnail).then((res) => {
                        setImageURI(res);
                        setShowPreviouseImage(true);
                    }); 
                } else {
                    Storage.get(image?.s3ObjectPathThumbnail).then((res) => {
                        setImageURI(res);
                        setShowPreviouseImage(false);
                    });   
                }
            }
        };
        cameraDataUseEffFunc.current = cameraDataUseEff;

        useEffect(() => {
            cameraDataUseEffFunc.current();
        }, [ s3ObjectPathThumbnail, imageDateTime, ]);

        return (
            <>
                {imageURI ? (
                    <img
                        src={imageURI}
                        alt='arvhive Camera'
                        className={imageClassName}
                        onError={(e) => {
                            if (theme === "dark") {
                                e.target.setAttribute("src", greyCameraDark);
                            } else {
                                e.target.setAttribute("src", greyCamera);  
                            }
                        }
                            
                        }
                    />
                ) : (
                    <div className={defaultClassName}>
                        <img
                            src={ theme === "dark" ? 
                                require("../../assets/images/greycam-dark.png") : 
                                require("../../assets/images/greycam.png")}
                            alt='archive Camera'
                        />
                    </div>
                )}
            </>
        );
    };

    const checkIfMarked = (id) => {
        return pinnedCams.includes(id);
    };

    console.log('Role:', Role); // Check the role value
    console.log('Camera Name:', camera.cameraName); // Check if cameraName exists

    return (
        <Col
            sm={12}
            md={listView ? 12 : 6}
            lg={listView ? 12 : 4}
            className='thumbnails__col'
        >
            <div className='single-project__thumbnails'>
                <div className='single-project__camerainfo'>
                    <span className='single-project__cameraname'>
                        <TiMediaRecord
                            color={
                                camera.status === "active"
                                    ? " #00B607"
                                    : camera.status === "inactive"
                                        ? "#C5C5C5"
                                        : camera.status === "failure" && "#D05E5E"
                            }
                            size={20}
                        />
                        &nbsp;
                        {(Role === "admin" || Role === "crew") && (
                            <span 
                                onClick={() => history.push({ pathname: "/cams", state: { camera: camera.cameraName } })}
                                className="single-project__cameraname-link"
                            >
                                {camera.cameraName}
                            </span>
                        )}
                        {(Role === "admin" || Role === "crew") &&
                        camera.cameraAlias ? (
                                <span 
                                    className='single-project__cam-alias'
                                >
                                    {" "}
                                    {camera.cameraAlias}
                                </span>
                            ) : (
                                <span> {camera.cameraAlias}</span>
                        )}
                        {(Role !== "admin" && Role !== "crew") && (
                            <span
                                className="single-project__cameraname-link"
                            >
                                {camera.cameraName}
                            </span>
                        )}
                    </span>
                    <span className='single-project__date'>
                        <FaRegCalendarAlt size={16} />
                        &nbsp;&nbsp;
                        {datetime > projectStartDate ? (
                            <span>
                                {moment(datetime).format("DD.MM.YYYY") + " "} |{" "}
                                {setTime?.substring(0, 5)}
                            </span>
                        ) : (
                            <span>--.--.---- | --:--</span>
                        )}
                        {(Role === "clientall" || Role === "superuser" || Role === "clientcamonly" ) &&
                         <BookmarkAddedIcon
                             sx={{ fontSize: "24px", marginLeft: "5px", cursor: "pointer", color: checkIfMarked(camera.id) ? "#1181f2" : "#cacaca"  }}
                             onClick={() => {setPinnedCamFunc(camera.id);}}
                         />
                        }
                       
                    </span>
                </div>

                <div
                    onClick={() => {
                        setCameraPopUp(true);
                        setCameraId(camera.id);
                        onClickFunc(camera);
                    }}
                    className='btn single-project-image-box'
                    style={{ width: "100%" }}
                >
                    {CamsImageProject(
                        listView
                            ? "singleproject-image__list"
                            : "singleproject-image",
                        listView
                            ? "default-singleproject-img__list"
                            : "default-singleproject-img"
                    )}
                </div>

                <div
                    className='single-project__btn-group'
                    style={
                        listView
                            ? { justifyContent: "flex-end" }
                            : { justifyContent: "center" }
                    }
                >
                    {!(Role === "clientcamonly") ? (
                        <button
                            className={
                                listView
                                    ? "single-project__archive-btn-list"
                                    : "single-project__archive-btn"
                            }
                            onClick={() =>
                                history.push(
                                    `/projects/${params?.id}/archive/${camera.id}`
                                )
                            }
                        >
                            {translate("archive")}
                        </button>
                    ) : (
                        ""
                    )}
                    {camera.liveStream && (
                        <button
                            className='single-project__live-btn'
                            onClick={() => {
                                setLivePopUp(true);
                                setLiveState({
                                    cameraName: camera.cameraName,
                                    liveStreamLink: camera.liveStreamLink,
                                });
                            }}
                        >
                            Live
                        </button>
                    )}
                    {(camera.timelapseEnabled && !(Role === "clientcamonly")) && (
                        <button
                            className='single-project__live-btn'
                            onClick={() => {
                                setTimelapsePopup(true);
                                setLiveState({
                                    cameraName: camera.cameraName,
                                    liveStreamLink: camera.liveStreamLink,
                                    projectId: camera.project?.id
                                });
                            }}
                        >
                            {translate("timelapse")}
                        </button>
                    )}
                </div>
            </div>
        </Col>
    );
};

SingleProjectCard.propTypes = {
    camera: PropTypes.object,
    listView: PropTypes.bool,
    Role: PropTypes.string,
    setCameraPopUp: PropTypes.func,
    setCameraId: PropTypes.func,
    onClickFunc: PropTypes.func,
    setLivePopUp: PropTypes.func,
    setLiveState: PropTypes.func,
    setTimelapsePopup: PropTypes.func,
    history: PropTypes.any,
    params: PropTypes.any,
    setPinnedCamFunc: PropTypes.func,
    pinnedCams: PropTypes.array,
};

export default SingleProjectCard;
